<div fxLayoutAdivgn="center center">
    <div class="page-body" *ngIf="!isNotFound else nosite" [ngClass]="{'html-template-page': isHtml, 'desktop': deviceDetector.isDesktop()}">
        <ng-container *ngIf="pageJson?.headerBanner?.url">
            <div class="top-image" [ngStyle]="{'margin-bottom':pageJson?.isDisplayDepositButton ? '16px':'12px'}">
                <!-- <img [src]="pageJson?.headerBanner?.url" alt="batch icon" class="batch-icon"/> -->
                <img [src]="pageJson?.headerBanner?.url" [ngStyle]="{'border-radius':pageJson?.isDisplayDepositButton ? '12px 12px 0px 0px':'12px'}" alt="header banner" />
                <div class="btn-content" *ngIf="pageJson?.isDisplayDepositButton">
                    <button [routerLink]="paymentUrl" round-button filled="filled" color="warn" type="button" i18n>Deposit</button>
                </div>
            </div>
        </ng-container>
        <div class="content" [innerHTML]="body"></div>
    </div>
</div>
<ng-container *ngIf="standalone">
    <router-outlet></router-outlet>
</ng-container>

<ng-template #nosite>
    <app-mobile-not-found></app-mobile-not-found>
</ng-template>