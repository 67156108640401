import { Component } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { LoaderService } from 'src/app/services/utils/loader/loader.service';
import { takeUntil } from 'rxjs/operators';
import { LicenceService } from 'src/app/services/utils/licence.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent extends BaseComponent {

  isShow = false;

  constructor(private loaderService: LoaderService,
     protected licenceService: LicenceService) { super() }

  ngOnInit(): void {
    this.loaderService.attach().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.isShow = resp;
    })
  }

}
