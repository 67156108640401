<ng-container *ngIf="!toggle">
    <div class="sidnav-wrapper">
        <div class="top-container" fxLayout="row" fxLayoutAlign="start center">
            <!-- <button mat-icon-button (click)="closeSideNav()"><img src="assets/desktop/ham-close-btn.svg"></button> -->
            <app-close-dialog-button (click)="closeSideNav()" [customClass]="'medium'" [position]="true"></app-close-dialog-button>
            <img class="logo-top" src="{{licenceService.getKey('logo')}}" alt="logo-green">
        </div>
        <div class="list-menu-1">
            <ng-container *ngIf="!isLogin else toploggeduser">
                <div class="open-account-button">
                    <button round-button color="accent" (click)="signup()" filled="filled" size="large" i18n>Open Account</button>
                </div>
            </ng-container>
            <ng-template #toploggeduser>
                <div class="logged-top-info" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
                    <app-profile-info (closeEvent)="closeSideNav()" [visible]="isVisible" size="small"></app-profile-info>
                    <app-top-menu-balance class="balance-info" (closeEventDeposit)="closeSideNav()"></app-top-menu-balance>
                </div>
            </ng-template>
            <mat-selection-list [multiple]="false" class="nav-bar-first-three">
                <a routerLink="/">
                    <mat-list-option [value]="" (click)="closeSideNav()">
                        <img mat-list-icon src="assets/desktop/home.svg"> <span i18n>Home</span>
                    </mat-list-option>
                </a>
                <a [routerLink]="slot">
                    <mat-list-option [value]="" (click)="closeSideNav()">
                        <img mat-list-icon src="assets/desktop/icon-menu-slots.svg"> <span i18n>Slots</span>
                    </mat-list-option>
                </a>
                <a [routerLink]="liveCasinoRoute">
                    <mat-list-option [value]="" (click)="closeSideNav()" [routerLink]="liveCasinoRoute">
                        <img mat-list-icon src="assets/desktop/cards.svg"> <span i18n>live casino</span>
                    </mat-list-option>
                </a>
            </mat-selection-list>
        </div>
        <mat-selection-list [multiple]="false" class="list-menu-2">
            <ng-container *ngFor="let menu of childData.topMenuItems; let i = index;">
                <ng-container *ngIf="!menu.requireLogin then menuitem">
                </ng-container>
                <ng-container *ngIf="menu.requireLogin && isLogin then menuitem">
                </ng-container>
                <ng-template #menuitem>
                    <div *ngIf="menu.page" [class]="menu.colorName !== null?menu.colorName:'regular'" #firstNavBar>
                        <a routerLink="/{{ menu.page?.category }}/{{ menu.page?.name }}">
                            <mat-list-option (click)="closeSideNav()" [value]="">
                                <img mat-list-icon src="{{menu.icon?.url}}"> <span>{{menu.name}}</span>
                            </mat-list-option>
                        </a>
                    </div>
                    <div *ngIf="menu.inAppUrl" [class]="menu.colorName !== null?menu.colorName:'regular'">
                        <a routerLink="/{{ menu.inAppUrl }}">
                            <mat-list-option (click)="closeSideNav()" [value]="">
                                <img mat-list-icon src="{{menu.icon?.url}}"> <span>{{menu.name}}</span>
                                <img class="docs-needed-icon" *ngIf="menu.inAppUrl === 'upload-documents' && hasDocumentRequest" mat-list-icon src="{{licenceService.getKey('icon-exclamation')}}">
                                <div class="pending-withdrawals-icon" *ngIf="menu.inAppUrl === 'pending-withdrawals' && !!pendingList?.count" fxLayout="row" fxLayoutAlign="center center" mat-list-icon>
                                    <div class="count">{{pendingList?.count}}</div>
                                </div>
                                <div class="inbox-icon" *ngIf="menu.inAppUrl === 'inbox' && !!unreadMessages" fxLayout="row" fxLayoutAlign="center center" mat-list-icon>
                                    <div class="count">{{unreadMessages}}</div>
                                </div>
                            </mat-list-option>
                        </a>
                    </div>
                </ng-template>
            </ng-container>
        </mat-selection-list>
        <mat-form-field class="translate select-language" appearance="outline">
            <mat-label></mat-label>
            <mat-select panelClass="language-overlay d-language-overlay" [value]="selectedLanguage?.name" (selectionChange)="setCookie($event)">
                <mat-select-trigger class="selected-container">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <img [src]="selectedLanguage?.flag?.url">
                    <span>{{selectedLanguage?.fullName}}</span>
                </div>
                    <mat-icon matSuffix>arrow_forward_ios</mat-icon>
                </mat-select-trigger>
                <mat-option *ngFor="let item of childData?.languages" [value]="item?.name">
                    <a href="/{{item?.name}}/">
                        <img class="select-img" [src]="item?.flag.url">
                        <span>{{item?.fullName}}</span>
                    </a>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <ng-container *loggedRender>
            <div class="menu-divider">&nbsp;</div>
            <div class="logout-wrapper">
                <app-logout></app-logout>
            </div>
        </ng-container>
    </div>
</ng-container>